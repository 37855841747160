<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<FormPages
		v-else
		:page-list="pageList"
		:is-loading="isCreating"
		:is-duplicate-slug="isDuplicateSlug"
		@onClearErrorSlug="handleClearErrorSlug"
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FormPages from '@/components/FormPages.vue';
import { pathOr, scrollToTop } from '../assets/js/helpers';

export default {
	name: 'PageCreate',
	components: {
		FormPages,
	},
	data() {
		return {
			isDuplicateSlug: false,
		};
	},
	computed: {
		...mapState('pages', {
			list: 'list',
			create: 'create',
		}),
		...mapGetters({
			pageList: 'pages/pageList',
		}),

		isLoading() {
			return this.list.isLoading;
		},
		isCreating() {
			return this.create.isLoading;
		},
	},
	created() {
		this.getPages();
	},
	methods: {
		...mapActions({
			getPages: 'pages/getPages',
			createPage: 'pages/createPage',
		}),
		async handleSubmit(params = {}) {
			try {
				await this.createPage(params);

				this.$router.push({ name: 'Pages' });
			} catch (error) {
				this.isDuplicateSlug = !!(pathOr(false, ['response', 'data', 'errors', 'slug', 0])(error));

				scrollToTop();
			}
		},
		handleClearErrorSlug() {
			this.isDuplicateSlug = false;
		},
	},
};
</script>

